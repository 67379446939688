import React from "react";
import {Row, Image, Col, Container, Button} from "react-bootstrap";
import './home.sass'
import homeIcon from '../../assets/home.png'
import { IoEnterOutline } from "react-icons/io5"
import { FiDownload } from 'react-icons/fi'

import facebook from '../../assets/social-media/facebook.svg'
import youtube from '../../assets/social-media/youtube.svg'
import linkedin from '../../assets/social-media/linkedin.svg'
import instagram from '../../assets/social-media/instagram.svg'
import tiktok from '../../assets/social-media/tiktok.svg'
import lsac_site from '../../assets/social-media/lsac_site.svg'
import { useContext } from "react";
import AppContext from "../../context";

const Home = () => {
    const {loading, isLoggedIn} = useContext(AppContext);

    return(
        <Container className="home">
            <Row>
                <Col md={6} sm={12} xs={12} className="text-center px-5">
                    <Image className="mx-auto w-75 w-md-50" src={homeIcon} alt={homeIcon}></Image>
                    <Container fluid className="text-center w-75 w-md-50 mt-5 p-0">
                        <div className="social-media">
                            <a href ="https://www.facebook.com/LsacBucuresti/">
                                <Image className="social-icon" src={facebook} alt={facebook} />
                            </a>
                            <a href = "https://www.youtube.com/user/LSACBucuresti">
                                <Image className="social-icon" src={youtube} alt={youtube} />
                            </a>
                            <a href = "https://ro.linkedin.com/company/lsac-bucuresti">
                                <Image className="social-icon" src={linkedin} alt={linkedin} />
                            </a>
                            <a href = "https://www.instagram.com/lsacbucuresti/">
                                <Image className="social-icon" src={instagram} alt={instagram} />
                            </a>
                            <a href = "https://www.tiktok.com/@lsacbucuresti">
                                <Image className="social-icon" src={tiktok} alt={tiktok} />
                            </a>
                            <a href = "https://lsacbucuresti.ro/">
                                <Image className="social-icon" src={lsac_site} alt={lsac_site} />
                            </a>
                        </div>
                    </Container>
                </Col>
                <Col md={6} sm={12} xs={12} className="right-side">
                    <div>
                        <p className="large-text">Let the hunt begin!</p>
                        <p className="text">Foaie verde sunătoare...când venim la vânătoare?</p>
                        <p className="text">Dacă te crezi destul de perspicace încât să poți rezolva toate ghicitorile pe care ți le-am pregătit, te așteptăm la Treasure Hunt, competiția care te îndeamnă la <strong>explorarea Bucureștiului</strong> prin intermediul unei vânători de comori. <strong>Ghicitorile intrigante, traseele antrenante</strong> și <strong>joculețele distractive</strong> te încurajează să îți testetzi logica, atenția și spiritul de echipă!</p>
                        <p className="text">Înainte să cauți comori, caută-ți echipă! Am ușurat noi munca aceasta pentru tine, pentru că ediția aceasta <strong>poți participa alături de prieteni de la orice facultate!</strong></p>
                        <p className="text">Ți-am captat atenția? Atunci precum X-ul marchează comoara, marchează și TU în calendarul tău <strong>Treasure Hunt 2024!</strong></p>
                        <a href="/rules">Citește regulamentul!</a>
                    </div>

                    <div>
                        <table className="buttons">
                            <tbody>
                                {/* <tr><Button variant="primary" className="button-fill">
                                    <a href="https://play.google.com/store/apps/details?id=lsac.treasure_hunt_app">Google Play</a>
                                    <FiDownload size={25} className="trailing-icon" />
                                </Button></tr>

                                <tr><Button variant="primary" className="button-fill">
                                    <a href="https://apps.apple.com/ro/app/lsac-treasure-hunt/id1590227374">App Store</a>
                                    <FiDownload size={25} className="trailing-icon" />
                                </Button></tr> */}
                            </tbody>
                        </table>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Home
