import React, {useContext, useEffect, useState} from "react";
import {Row, Image, Col, InputGroup, Container, Button, Form, Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import './team-setup.sass';
import AppContext from "../../context";
import { RiTreasureMapFill } from 'react-icons/ri'
import { FaHandHoldingHeart } from "react-icons/fa"
import { ImArrowRight } from "react-icons/im"

import loginIcon from '../../assets/login.png'
import { createTeam, joinTeam } from "../../network/apiCall";

const TeamSetup = () => {

    const {isLoggedIn, setIsLoggedIn, loading, user} = useContext(AppContext);
    const navigate = useNavigate();

    const [teamName, setTeamName] = useState("");
    const [joinToken, setJoinToken] = useState("");
    const [yesCaptain, setYesCaptain] = useState(false);
    const [privacy, setPrivacy] = useState(false);
    const [rules, setRules] = useState(false);
    const [privacyjoin, setPrivacyjoin] = useState(false);
    const [rulesjoin, setRulesjoin] = useState(false);

    const [error, setError] = useState("");

    useEffect(() => {
        if (!loading && !isLoggedIn) {
            navigate('/login');
        }
    }, [loading]);

    useEffect(() => {
        if (!loading && isLoggedIn && user.noTeam) {
            navigate('/profile');
        }
    }, [loading]);

    const onChangeTeamName = e => {
        setTeamName(e.target.value);
    };
    const onChangeJoinToken = e => {
        setJoinToken(e.target.value);
    };

    const onCreateTeam = async () => {
        if (!yesCaptain || !rules || !privacy) {
            setError("Te rugam sa confirmi toate cele de mai sus!")
            return
        }
        const {data} = await createTeam(teamName);
        console.log(data)
        if (data.success) {
            navigate('/profile');
            window.location.reload(false);
        } else {
            setError(data.data.error);
        }
    };

    const onJoinTeam = async () => {
        console.log(privacyjoin)
        console.log(rulesjoin)
        if (!rulesjoin || !privacyjoin) {
            setError("Te rugam sa confirmi toate cele de mai sus!")
            return
        }
        const {data} = await joinTeam(joinToken)
        console.log(data)
        if (data.success) {
            navigate('/profile');
            window.location.reload(false);
        } else {
            setError(data.data.error);
        }
    };

    return(
        <Container className="teamsetup">
            <Row>
                <Col md={6} className="text-center image-container p-md-5 p-5 order-md-2">
                    <Image className="w-75 w-md-50" src={loginIcon} alt={loginIcon}></Image>
                </Col>
                <Col md={6} sm={12} xs={12} className="left-side my-auto order-md-1">
                    <p className="large-text">Step 2: Manage your team</p>
                    <br />

                    <Tabs defaultActiveKey="join" className="mb-3 tabs-container" fill>
                        <Tab eventKey="create" title="Create">
                            <Form className="my-form">
                                <InputGroup className="mb-2">
                                    <Form.Control placeholder="Team Name" onChange={e => onChangeTeamName(e)} value={teamName}/>
                                    <RiTreasureMapFill className="form-icon" />
                                </InputGroup>
                                <InputGroup className="mb-2">
                                    <Form.Check type="checkbox" value={yesCaptain} onChange={function () { setYesCaptain(!yesCaptain)}} label="Sunt de acord să îmi asum rolul de căpitan." />
                                </InputGroup>
                                <InputGroup className="mb-2">
                                    <Form.Check type="checkbox" value={privacy} onChange={function () { setPrivacy(!privacy)}} label="Am citit și sunt de acord cu Privacy Policy." />
                                </InputGroup>
                                <InputGroup className="mb-2">
                                    <Form.Check type="checkbox" value={rules} onChange={function () { setRules(!rules)}} label="Am citit și sunt de acord cu Regulamentul." />
                                </InputGroup>
                                {error && <p className="error">{error}</p>}
                            </Form>
                            <div className="text-center">
                                <Button variant="primary" onClick={onCreateTeam}>
                                    Create team
                                    <ImArrowRight className="trailing-icon" />
                                </Button>
                            </div>
                        </Tab>
                        <Tab eventKey="join" title="Join">
                            <Form className="my-form">
                                <InputGroup className="mb-2">
                                    <Form.Control placeholder="Join Token" onChange={e => onChangeJoinToken(e)} value={joinToken}/>
                                    <FaHandHoldingHeart className="form-icon" />
                                </InputGroup>
                                <InputGroup className="mb-2">
                                    <Form.Check type="checkbox" value={privacyjoin} onChange={function () { setPrivacyjoin(!privacyjoin)}} label="Am citit și sunt de acord cu Privacy Policy." />
                                </InputGroup>
                                <InputGroup className="mb-2">
                                    <Form.Check type="checkbox" value={rulesjoin} onChange={function () { setRulesjoin(!rulesjoin)}} label="Am citit și sunt de acord cu Regulamentul." />
                                </InputGroup>
                                {error && <p className="error">{error}</p>}
                            </Form>
                            <div className="text-center">
                                <Button variant="primary" onClick={onJoinTeam}>
                                    Join team
                                    <ImArrowRight className="trailing-icon" />
                                </Button>
                            </div>
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </Container>
    );
}

export default TeamSetup;