import React, { useEffect, useState} from "react";
import {Row, Image, Col, InputGroup, Container, Button, Form, Nav} from "react-bootstrap";
import { forgotPassword } from "../../network/apiCall";

import { GrMail } from "react-icons/gr"
import { ImArrowRight } from "react-icons/im"

import loginIcon from '../../assets/login.png'
import './forgot-pass.sass'

const ForgotPass = () => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");    
    const [error, setError] = useState("");

    const onChangeEmail = e => {
        setEmail(e.target.value);
    };


    const onSubmitHandler = async () => {
        const { data } = await forgotPassword(email);
        if (data.success) {
            setMessage(data.data);
            setError("");
            setEmail("");
        } else {
            setError(data.data);
            setMessage("");
            setEmail("");
        }
    };

    return(
        <Container className="login">
            <Row>
                <Col md={6} className="text-center image-container p-md-5 p-5 order-md-2">
                    <Image className="w-75 w-md-50" src={loginIcon} alt={loginIcon}></Image>
                </Col>
                <Col md={6} sm={12} xs={12} className="left-side my-auto order-md-1">
                    <p className="large-text">Welcome!</p>
                    <p className="text">Please input your details and hit Login!</p>

                    <Form className="pt-5 my-form">
                        <InputGroup className="mb-2">
                            <Form.Control type="email" placeholder="Email" onChange={e => onChangeEmail(e)} value={email}/>
                            <GrMail className="form-icon" />
                        </InputGroup>
                        <div className="text-center">
                            {error ? <p className="error">{error} </p> :
                                <p className="message">{message}</p>}
                            <Button variant="primary" onClick={onSubmitHandler}>
                                Send
                                <ImArrowRight className="trailing-icon"/>
                            </Button>
                        </div>
                        <div className="text-center mt-2">
                        <p className="text">Ti-ai amintit parola?  </p>
                            <Nav.Link href="/login">Revino la pagina de login</Nav.Link>
                            {/* <br/>
                            <p>Don't have an account? <Nav.Link href="/register">Create one today</Nav.Link></p> */}
                        </div>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default ForgotPass;