import React, { useEffect } from "react";
import './App.sass';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import NavBar from './components/navbar'
import Login from './components/auth/login';
import Register from './components/auth/register';
import OnePager from './components/one-pager';
import Rules from './components/static-text/regulament';
import Privacy from './components/static-text/privacy_policy';
import ComingSoon from './components/coming-soon';
import TeamSetup from './components/auth/team-setup';
import Profile from "./components/auth/profile";
import ForgotPass from "./components/auth/forgot-pass";
import HowTo from "./components/static-text/how_to";
import ResetPass from "./components/auth/reset-pass";

function App() {

  const Comp = () => { 
    useEffect(() => {
      window.location="https://forms.gle/mvmeXWcf9UfhXUeu9";
    }, [])
    return <></>
  }

  return (
    <BrowserRouter className="main-container">
      <NavBar/>
      <div className="dashboard container-fluid">
        <div className="row">
          <Routes>
            <Route path="/" exact element={<OnePager />} />
            <Route path="/login" exact element={<Login />} />
            <Route path="/register" exact element={<Register />} />
            <Route path="/howto" exact element={<ComingSoon />} />
            <Route path="/rules" exact element={<Rules />} />
            <Route path="/privacy" exact element={<Privacy />} />
            <Route path="/team-setup" exact element={<TeamSetup />} />
            <Route path="/profile" exact element={<Profile />} />
            <Route path="/forgot-pass" exact element={<ForgotPass />} />
            <Route path="/reset-pass/:token" exact element={<ResetPass />} />
            {/* <Route path="/feedback" element={<Comp />} /> */}
            <Route
              path="*"
              element={<Navigate to="/" />}
            />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
