import React from "react";
import { Container } from "react-bootstrap";
import './team.sass'
import TeamCard from './teamCard'
import { team } from "../../../assets/team"

const Team = () => {

    return(
        <Container className="team">
            <span className="label">
                <p className="large-text text-center">Meet our team!</p>
            </span>
            <Container className="wrapper">                            
                <Container className="row">
                    {
                        team.map((teammate, idx) => {
                                return <TeamCard key={idx} member={teammate} />
                            }
                        )
                    }
                </Container>
    

            </Container>
        </Container>
    );
}

export default Team
