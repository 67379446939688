import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(async (config) => {
    const token = localStorage.getItem("token");
    config.headers.Authorization = (token ? `Bearer ${token}` : '');
    config.headers.ContentType = 'application/json';
    return config;
});

export const register = async (email, password, confPassword, username, lastName, firstName, phoneNumber, noTeam, isUPB, appleID) => (
    await instance.post('auth/register', {email, password, confPassword, username, lastName, firstName, phoneNumber, noTeam, isUPB, appleID})
);

export const login = async (email, password) => (
    await instance.post('auth/login', {email, password})
);

export const forgotPassword = async (email) => (
    await instance.post('auth/forgotPass', { email })
);

export const resetPassword = async (email, password, confirmPassword, token) => (
    await instance.post('auth/reset-pass', {email, password, confirmPassword, token})
)
export const getUser = async () => (
    await instance.get('me/user')
);

export const getTeam = async () => (
    await instance.get('me/team')
);

export const createTeam = async (teamName) => (
    await instance.post('teams/', {teamName})
);

export const joinTeam = async (joinToken) => (
    await instance.post("teams/join", {joinToken})
);

export const leaveTeam = async () => (
    await instance.post("teams/leave", {})
);

export const deleteAccount = async () => (
    await instance.delete("users", {})
);

export const contact = async (teamName, name, email, message) => (
    await instance.post("contact", {teamName, name, email, message})
);
