export const team = [
    {
        "name": "RAFAEL CHIȚAN",
        "photo": "./team/Rafael-before.jpg",
        "hoverPhoto": "./team/Rafael-after.jpg",
        "position": "Manager de Proiect",
        "phoneNumber": "+40 770 796 598"
    },
    {
        "name": "TUDOR DIACONU",
        "photo": "./team/Toto-before.jpg",
        "hoverPhoto": "./team/Toto-after.jpg",
        "position": "Vicepreședinte",
        "phoneNumber": "+40 747 331 899"
    },
    {
        "name": "CRISTINA ANGHEL",
        "photo": "./team/Cristina-before.jpg",
        "hoverPhoto": "./team/Cristina-after.jpg",
        "position": "Manager Design",
        "phoneNumber": "+40 753 837 541"
    },
    {
        "name": "ALEXANDRU GHERGHICEANU",
        "photo": "./team/Alex-before.jpg",
        "hoverPhoto": "./team/Alex-after.jpg",
        "position": "Manager Fundraising",
        "phoneNumber": "+40 722 754 167"
    },
    {
        "name": "ANDREI MITEA",
        "photo": "./team/Andrei-before.jpg",
        "hoverPhoto": "./team/Andrei-after.jpg",
        "position": "Manager Ghicitori",
        "phoneNumber": "+40 746 069 696"
    },
    {
        "name": "BIANCA PETREA",
        "photo": "./team/Bianca-before.jpg",
        "hoverPhoto": "./team/Bianca-after.jpg",
        "position": "Manager Human Resources",
        "phoneNumber": "+40 730 785 253"
    },
    {
        "name": "ALEXANDRA BULGARU",
        "photo": "./team/Alexa-before.jpg",
        "hoverPhoto": "./team/Alexa-after.jpg",
        "position": "Manager IT",
        "phoneNumber": "+40 756 025 021"
    },
    {
        "name": "EMA DINU",
        "photo": "./team/Ema-before.jpg",
        "hoverPhoto": "./team/Ema-after.jpg",
        "position": "Manager Logistică",
        "phoneNumber": "+40 747 111 188"
    },
    {
        "name": "BEATRICE SĂLĂVĂSTRU",
        "photo": "./team/Beatrice-before.jpg",
        "hoverPhoto": "./team/Beatrice-after.jpg",
        "position": "Manager Multimedia",
        "phoneNumber": "+40 784 606 311"
    },
    {
        "name": "GEORGIANA GRIGOREANU",
        "photo": "./team/geo.jpg",
        "hoverPhoto": "./team/geo.jpg",
        "position": "Manager Public Relations",
        "phoneNumber": "+40 722 587 037"
    },
    {
        "name": "CRISTINA IONESCU",
        "photo": "./team/CristinaI-before.jpg",
        "hoverPhoto": "./team/CristinaI-after.jpg",
        "position": "Mentor",
        "phoneNumber": "+40 725 533 609"
    },
    {
        "name": "ANDREEA UDĂȚEANU",
        "photo": "./team/Andreea-before.jpg",
        "hoverPhoto": "./team/Andreea-after.jpg",
        "position": "Mentor",
        "phoneNumber": "+40 737 090 006"
    },
    {
        "name": "TEODORA MINEA",
        "photo": "./team/Teo-before.jpg",
        "hoverPhoto": "./team/Teo-after.jpg",
        "position": "Mentor",
        "phoneNumber": "+40 740 159 675"
    },
]