import React, {useContext} from 'react';
import { Col, Container, Row, Image, Button, Modal } from "react-bootstrap";
import profilePic from "../../assets/profile.png";
import password from '../../assets/password.svg';
import './profile.sass'
import AppContext from "../../context";
import { useNavigate } from "react-router-dom";
import { ImArrowRight } from "react-icons/im"
import { useEffect, useState } from 'react';
import { deleteAccount, getTeam, leaveTeam } from '../../network/apiCall';
import crown from '../../assets/crown_profile.svg';
import {BsTelephoneFill, BsPersonFill, BsPeopleFill} from "react-icons/bs"

export default function Profile() {
    const {setIsLoggedIn, user, hasTeam, loading} = useContext(AppContext);
    const navigate = useNavigate();
    const [team, setTeam] = useState("");
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onSubmitHandler = () => {
        localStorage.removeItem("token");
        setIsLoggedIn(false);
        navigate('/home');
    };

    useEffect(() => {
        if (user.email == null) return;

        getTeam()
        .then((teamObj) => {
            setTeam(teamObj.data.data.team);
        });
    }, [user]);

    const onClickProfile = () => {
        document.getElementById('team').classList.add("displaynone");
        document.getElementById('profile').classList.remove("displaynone");
    }

    const onClickTeam = () => {
        document.getElementById('profile').classList.add("displaynone");
        document.getElementById('team').classList.remove("displaynone");
    }

    const onClickGoTo = () => {
        navigate("/team-setup")
    }

    const onClickLeaveTeam = async () => {
        await leaveTeam();
        window.location.reload(false)
    }

    const onClickDeleteAccount = async () => {
        await deleteAccount();
        localStorage.removeItem("token");
        setIsLoggedIn(false);
        navigate("/home")
        window.location.reload(false)
    }

    return (
        <Container className="profile">
            <Row>
                <Col lg={3} md={4} sm={12} xs={12} className="sidebar text-center">
                        <Image className="w-75 w-md-50 mx-auto squid" src={profilePic} alt={profilePic}></Image>

                        <div className='mx-auto'>
                            <span className="label highlight" onClick={onClickProfile}>
                                <BsPersonFill className="icon mb-3" />
                                <p className="sidebar-text">&nbsp;&nbsp;Date profil</p>
                            </span>
                            <span className="label highlight" onClick={onClickTeam}>
                                <BsPeopleFill className="icon mb-3" />
                                <p className="sidebar-text">&nbsp;&nbsp;Echipă</p>
                            </span>
                            {/* <span className="label">
                                <img className="icon mb-3" src={password} alt={password} />
                                <p className="sidebar-text">Parolă</p>
                            </span> */}
                        </div>

                        <Button className='w-75 w-md-50' variant="primary" onClick={onSubmitHandler}>
                            Logout
                            <ImArrowRight className="trailing-icon" />
                        </Button>
                </Col>

                <Col className='right-side'>
                    <div id='team' className='displaynone'>
                        {
                            !loading?
                            
                            user.noTeam ?

                            <div>
                                <p className='warning giant-text'>Nu ești într-o echipă</p>
                                <p className='large-text'>Ai ales să fii asignat aleator într-o echipă de către organizatori. Dacă, între timp, ți-ai găsit o echipă, șterge-ți contul în secțiunea de Date Profil și creează-ți unul nou împreuna cu prietenii tăi!</p>
                            </div>

                            :

                            hasTeam ?

                            <div>
                                <div>
                                    <p className='large-text'>Nume echipă</p>
                                    <p className='text'>{team.name}</p>
                                    <div className="underline"></div>
                                </div>
                                <div>
                                    <p className='large-text'>Rol</p>
                                    <span className='text'>{
                                        user.isCaptain ?
                                            <p className="captain"><img className="icon crown" src={crown} alt={crown} />&nbsp;&nbsp;&nbsp;Căpitan</p>
                                            : "Membru"
                                        }
                                    </span>
                                    <div className="underline"></div>
                                </div>
                                <div>
                                    <p className='large-text'>Format echipă</p>
                                    {team != null ? team.members.map((member) =>
                                        <div key={member.username}>
                                            <span className='captain text'>{
                                                member.isCaptain ?
                                                    <p className="captain"><img className="icon crown" src={crown} alt={crown} />&nbsp;&nbsp;&nbsp;Căpitan:&nbsp;&nbsp;&nbsp;</p>
                                                    : <p className="captain">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Membru:&nbsp;&nbsp;&nbsp;</p>
                                                }
                                                <p className='text'>{member.lastName + " " + member.firstName}</p>
                                            </span>
                                            <span className='captain text'>
                                                <BsTelephoneFill />
                                                <p className='text'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Telefon:&nbsp;&nbsp;&nbsp;</p>
                                                <p className='text'>{member.phoneNumber}</p>
                                            </span>
                                            <div className="underline"></div>
                                        </div>
                                    ) : <div />}
                                </div>

                                <div>
                                    <p className='large-text'>Token echipă</p>
                                    <p className='text'>{team?.joinToken}</p>
                                    <div className="underline"></div>
                                </div>

                                <Button className='w-50 m-0 mb-5' variant="primary" onClick={onClickLeaveTeam}>
                                    Leave Team
                                    <ImArrowRight className="trailing-icon" />
                                </Button>
                            </div>

                            :

                            <div>
                                <p className='warning giant-text'>Nu ești într-o echipă</p>
                                <p className='warning large-text'>La înscriere, ai specificat că ai o echipă deja formată, dar încă nu faci parte dintr-una! Pană la finalul perioadei de înscriere, trebuie să fii într-o echipă, altfel riști să fii descalificat.</p>
                                <p className='warning large-text'>Dacă între timp, ai decis că dorești să te înscrii singur și să fii asignat aleator într-o echipă de către organizatori, șterge-ți contul în secțiunea Date profil și creează-ți unul nou.</p>
                                <Button className='w-50' variant="primary" onClick={onClickGoTo}>
                                    Go to team setup
                                    <ImArrowRight className="trailing-icon" />
                                </Button>
                            </div>

                            :

                            <div />
                        }
                    </div>

                    <div id='profile'>
                        <div>
                            <p className='large-text'>Nume</p>
                            <p className='text'>{user?.lastName}</p>
                            <div className="underline"></div>
                        </div>
                        
                        <div>
                            <p className='large-text'>Prenume</p>
                            <p className='text'>{user?.firstName}</p>
                            <div className="underline"></div>
                        </div>

                        <div>
                            <p className='large-text'>Email</p>
                            <p className='text'>{user?.email}</p>
                            <div className="underline"></div>
                        </div>
                        {user?.appleID ?
                            <div>
                                <p className="large-text">Apple ID</p>
                                <p className="text">{user?.appleID}</p>
                                <div className="underline"></div>
                            </div> : <div />
                        }
                        <div>
                            <p className='large-text'>Telefon</p>
                            <p className='text'>{user?.phoneNumber}</p>
                            <div className="underline"></div>
                        </div>

                        <Button className='w-50 m-0 mb-5' variant="primary" onClick={handleShow}>
                            Delete account
                            <ImArrowRight className="trailing-icon" />
                        </Button>

                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton />
                            <Modal.Body>
                                <p className='large-text'>Are you sure you want to delete your account?</p>
                                <p className='large-text'>This action is irreversible.</p>

                                <Button className='w-50 m-0 delete' variant="primary" onClick={onClickDeleteAccount}>
                                    I'm sure, delete account
                                    <ImArrowRight className="trailing-icon" />
                                </Button>
                            </Modal.Body>
                        </Modal>
                    </div>
                </Col>
            </Row>

        </Container>
    )
}
