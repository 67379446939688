import React, {useContext, useEffect, useState} from "react";
import {Row, Image, Col, InputGroup, Container, Button, Form, Nav} from "react-bootstrap";
import { login } from "../../network/apiCall";
import { useNavigate } from "react-router-dom";
import './login.sass';
import AppContext from "../../context";
import { GrMail } from "react-icons/gr"
import { HiLockClosed } from "react-icons/hi"
import { ImArrowRight } from "react-icons/im"

import loginIcon from '../../assets/login.png'

const Login = () => {

    const {isLoggedIn, setIsLoggedIn, loading} = useContext(AppContext);
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [error, setError] = useState("");

    useEffect(() => {
        if (!loading && isLoggedIn) {
            navigate('/profile');
        }
    }, [loading]);

    const onChangeEmail = e => {
        setEmail(e.target.value);
    };
    const onChangePassword = e => {
        setPassword(e.target.value);
    };

    const onSubmitHandler = async () => {
        const {data} = await login(email, password);
        if (data.success) {
            localStorage.setItem("token", data.data.token);
            setIsLoggedIn(true);
            navigate('/profile');
            window.location.reload(false);
        } else {
            setError("Credentiale invalide!");
            setPassword("");
        }
    };

    return(
        <Container className="login">
            <Row>
                <Col md={6} className="text-center image-container p-md-5 p-5 order-md-2">
                    <Image className="w-75 w-md-50" src={loginIcon} alt={loginIcon}></Image>
                </Col>
                <Col md={6} sm={12} xs={12} className="left-side my-auto order-md-1">
                    <p className="large-text">Welcome!</p>
                    <p className="text">Please input your details and hit Login!</p>

                    <Form className="pt-5 my-form">
                        <InputGroup className="mb-2">
                            <Form.Control type="email" placeholder="Email / Apple ID" onChange={e => onChangeEmail(e)} value={email}/>
                            <GrMail className="form-icon" />
                        </InputGroup>
                        <InputGroup className="mb-2">
                            <Form.Control type="password" placeholder="Password" onChange={e => onChangePassword(e)} value={password}/>
                            <HiLockClosed className="form-icon" />
                        </InputGroup>
                        <div className="text-center">
                            {error && <p className="error">{error}</p>}
                            <Button variant="primary" onClick={onSubmitHandler}>
                                Login
                                <ImArrowRight className="trailing-icon" />
                            </Button>
                        </div>
                        <div className="text-center mt-2">
                            <Nav.Link href="/forgot-pass">Forgot Password</Nav.Link>
                            {/* <br/>
                            <p>Don't have an account? <Nav.Link href="/register">Create one today</Nav.Link></p> */}
                        </div>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default Login;