import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import "./base.sass";
import rules from "../../assets/rules.png";
import TeamIssues from "../auth/team-issues";

const Rules = () => {
  return (
    <>
      <TeamIssues />
      <Container className="any rules">
        <Row>
          <Col
            lg={6}
            className="text-center image-container p-md-5 p-5 order-lg-2"
          >
            <Image className="w-75 w-md-50" src={rules} alt={rules}></Image>
          </Col>
          <Col lg={6} className="left-side my-auto order-lg-1">
            <p className="large-text">Regulament</p>
            <p className="text textred">
              Nu există un traseu predefinit. Toate ghicitorile aferente
              locațiilor vor fi vizibile încă de la începutul evenimentului,
              având libertatea să vă formați singuri traseul și să rezolvați
              ghicitorile în mod aleatoriu.
            </p>
            <p className="med-text">
              Check-in participanți : 19 Octombrie, ora 09:00
            </p>
            <p className="med-text">Deschidere eveniment : ora 10:00</p>
            <p className="med-text">Start competiție : ora 11:00</p>
            <p className="med-text">Stop competiție : ora 18:00</p>
            <p className="med-text">Crearea echipelor</p>
            <ul>
              <li className="text">Echipele vor fi formate din trei membri.</li>
              <li className="text">
                Cel puțin un membru din fiecare echipă trebuie să fie înmatriculat la o facultate din cadrul Universității Naționale de Știință și Tehnologie București.
              </li>
              <li className="text">
                Toți participanții trebuie să fie studenți.
              </li>
              <li className="text">
                Nu contează anul de studiu al studenților.
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="padright left-side my-auto order-lg-1">
            <div>
              <p className="med-text">Check-in</p>
              <ul>
                <li className="text">
                  Va trebui să vă prezentați din timp pentru check-in. Având în
                  vedere că evenimentul va începe la ora 10:00, va trebui să
                  fiți prezenți începând cu ora 09:00 la Registration Desk și
                  pentru clarificarea nelămuririlor înainte de începerea
                  evenimentului.
                </li>
                <li className="text">
                  Pentru ca o echipă să fie eligibilă de participare, toți
                  membrii acesteia trebuie să se prezinte la check-in la ora
                  stabilită în program.
                </li>
                <li className="text">
                  Toți participanții trebuie să prezinte buletinul și carnetul de student sau legitimația de student, pe care participanții trebuie să le dețină fizic. De asemenea, pentru studenții cărora nu le-a fost încă emis carnetul de student, pot dovedi statutul de student UNSTPB, prezentând contul de studenti.pub.ro.
                </li>
                <li className="text">
                  Echipele o să primească un cartonaș unic și, de asemenea, o să
                  aibă desemnat un voluntar HR, ce va avea rolul să le valideze
                  side quest-urile pe parcursul competiției.
                </li>
                <li className="text">
                  Va fi realizat un tutorial de utilizare al aplicației pe baza căreia se va desfășura concursul, urmând mai apoi o sesiune de întrebări cu nelămuririle voastre.
                </li>
                <li className="text">
                  Startul - echipele vor aștepta obligatoriu citirea
                  regulamentului și strigarea startului înainte de a pleca spre
                  prima locație.
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={12} className="padright left-side my-auto order-lg-1">
            <div>
              <p className="med-text">Ghicitorile</p>
              <ul>
                <li className="text">
                  Pe parcursul evenimentului, fiecare echipă va putea vedea
                  toate ghicitorile cu punctajul, gradul de dificultate și
                  numărul de hint-uri aferent fiecăreia.
                </li>
                <li className="text">
                  Răspunsurile la ghicitori NU vor mai fi reprezentate de numele
                  locațiilor unde trebuie să ajungă echipa.
                </li>
                <li className="text">
                  După rezolvarea unei ghicitori, participanții vor primi numele
                  unei locații și totodată o parte din punctajul acesteia,
                  urmând să obțină restul punctelor când li se scanează codul QR
                  de la locație.
                </li>
                <li className="text">
                  Fiecare echipă își face propria strategie de parcurgere a
                  locațiilor, odată cu rezolvarea ghicitorilor.
                </li>
                <li className="text">
                  Numărul maxim de locații care pot fi ghicite simultan (fără a
                  merge pentru scanarea codului QR la checkpoint) este de 4.
                </li>
                <li className="text">
                  Puteți încerca răspunsul la o ghicitoare de oricâte ori vă
                  doriți până o ghiciți. După fiecare răspuns introdus, există
                  totuși un scurt cooldown.
                </li>
                <li className="text">
                  Participanții NU vor oferi informații echipelor adverse și nu
                  vor posta poze/filmulețe de la checkpoint decât dacă este
                  explicit permis sau încurajat de către voluntari (întrucât
                  acest lucru este și în detrimentul lor).
                </li>
              </ul>
            </div>
            <div>
              <p className="med-text">Transportul</p>
              <ul>
                <li className="text">
                  Este interzisă utilizarea mașinilor (proprii, ale prietenilor,
                  ale vecinilor, ale parintilor, etc.) și a serviciilor de
                  ridesharing (Uber, Bolt, Spark, etc) sau taximetrie.
                </li>
                <li className="text">
                  Se pot folosi mijloace de transport în comun (metrou, STB) sau
                  trotinete/biciclete.
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={12} className="padright left-side my-auto order-lg-1">
            <div>
              <p className="med-text">Checkpoint</p>
              <ul>
                <li className="text">
                  Fiecărei ghicitori din cadrul evenimentului îi este atribuită
                  o locație ce va fi aflată după rezolvarea acesteia.
                </li>
                <li className="text">
                  Fiecărei locații îi este atribuit un checkpoint fizic unde se
                  vor găsi voluntari LSAC pentru a putea finaliza ghicitoarea
                  respectivă.
                </li>
                <li className="text">
                  La checkpoint-uri, participanții vor avea posibilitatea să mai
                  facă un challenge opțional pentru a strânge mai multe puncte.
                  <strong>Toți membrii echipei trebuie să fie prezenți pentru
                  îndeplinirea provocărilor.</strong>
                </li>
              </ul>
            </div>
            <div>
              <p className="med-text">Voluntari</p>
              <ul>
                <li className="text">
                  La fiecare checkpoint al evenimentului Treasure Hunt Bucharest 2024 se vor găsi voluntari LSAC, care pot fi identificași ușor prin tricouri cu logo-ul ligii și steaguri LSAC.
                </li>
                <li className="text">
                  Voluntarii o să fie informați dacă o echipă a utilizat un
                  power up și o să le comunice membrilor faptul că respectiva
                  putere a fost folosită.
                </li>
                <li className="text">
                  Aceștia vor scana codul QR aferent fiecărei echipe.
                </li>
                <li className="text">
                  Abia după scanarea codului QR echipa va obține punctajul
                  complet la ghicitori (o parte din punctaj va fi obținut după
                  rezolvarea ghicitorilor).
                </li>
                <li className="text">
                  Voluntarii le vor explica participanților provocarea de la
                  locația respectivă și le vor scana codul QR aferent
                  challenge-ului dacă echipa reușește să îl îndeplinească.
                </li>
              </ul>
            </div>
            <div>
              <p className="med-text">Hint-uri</p>
              <ul>
                <li className="text">
                  Participanții vor avea posibilitatea de a recurge la hint-uri
                  pentru rezolvarea ghicitorilor la care se vor împotmoli.
                </li>
                <li className="text">
                  Acestea vor costa puncte, rezultând că dacă veți apela la un
                  hint pentru o ghicitoare, aceasta va fi punctată mai slab după
                  finalizarea ei.
                </li>
                <li className="text">
                  Costul hint-urilor va fi egal cu un procent din punctajul
                  total al ghicitorii (primul = 20%, al 2-lea = 30%, al 3-lea =
                  40%). Ghicitorile nu au număr egal de hint-uri.
                </li>
              </ul>
            </div>
            <div>
              <p className="med-text">Side Quest-uri</p>
              <ul>
                <li className="text">
                  Pe parcursul concursului, echipele o să primească, prin
                  intermediul aplicației câteva side quest-uri.
                </li>
                <li className="text">
                  Acestea nu sunt obligatorii, rămâne strict la latitudinea
                  participanților dacă doresc sau nu să le rezolve.
                </li>
                <li className="text">
                  După validarea unui side quest, echipa va primi un power up în
                  aplicație.
                </li>
              </ul>
            </div>
            <div>
              <p className="med-text">Power Up-uri</p>
              <ul>
                <li className="text">
                  Participanții vor obține un power up după validarea unui side
                  quest.
                </li>
                <li className="text">
                  Acestea pot fi folosite în orice moment al competiției.
                </li>
                <li className="text">
                  Power up-urile au rolul de a ajuta echipele pe parcursul
                  concursului.
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className="bg-gray sep">
          <Col lg={12} className="padright left-side my-auto order-lg-1">
            <p className="text textred textthick">
              La orice abatere de la regulile de mai sus, echipele se consideră
              descalificate.
            </p>
            <p className="med-text">
              DISCLAIMER: Regulamentul oficial Treasure Hunt Bucharest 2024
              poate suferi modificări în orice moment fără anunț în prealabil.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Rules;
