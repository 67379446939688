import React from "react";
import { Container } from "react-bootstrap";
import './sponsors.sass'

const Sponsor = (props) => {


    return(
        <Container className="col-lg-4 col-md-6 col-sm-12 py-4 justify-content-center">
            <div className="text-center">
                <img className="photo" src={props.sponsor.photo} alt={props.sponsor.photo} />
            </div>
        </Container>
    );
}

export default Sponsor
