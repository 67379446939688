import React, {useEffect, useState} from 'react';
import { getTeam, getUser } from './network/apiCall';

const AppContext = React.createContext();

export const TreasureProvider = ({children}) => {

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [hasTeam, setHasTeam] = useState(false);
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (localStorage.getItem("token") !== null) {
            getUser()
            .then((userObj) => {
                if (userObj.data.success) {
                    setIsLoggedIn(true);
                    setUser(userObj.data.data);
                } else {
                    localStorage.removeItem("token")
                    setLoading(false)
                }
            })
        } else {
            localStorage.removeItem("token");
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        if (user.email == null) return
        getTeam().then((teamObj) => {
            if (teamObj.data.success) {
                setHasTeam(true);
            }
            setLoading(false)
        })
    }, [user])

    return (
        <AppContext.Provider value={{isLoggedIn, setIsLoggedIn, loading, hasTeam, user}} >
            {children}
        </AppContext.Provider>
    );
};

export default AppContext;
