import React from "react";
import {Row, Image, Col, Container, Button} from "react-bootstrap";
import { useContext } from "react";
import AppContext from "../../context";
import './team-issues.sass'
import { IoEnterOutline } from "react-icons/io5"

const TeamIssues = () => {
    const {loading, isLoggedIn, user, hasTeam} = useContext(AppContext);

    return(
        <>
        {
            !loading && isLoggedIn && user.noTeam && !hasTeam ?
            <Container className="team-issues">
                <Row>
                    <p className="text warning">La înscriere, ai specificat că ai o echipă deja formată, dar încă nu faci parte dintr-una! Pană la finalul perioadei de înscriere, trebuie să fii într-o echipă, altfel riști să fii descalificat.</p>
                </Row>
                <Row className="right-side">
                    <Button variant="primary" className="button-fill">
                        <a href="/team-setup">Go to team setup</a>
                        <IoEnterOutline size={25} className="trailing-icon" />
                    </Button>
                </Row>
            </Container>
            :
            <div />
        }
        </>
    );
}

export default TeamIssues
