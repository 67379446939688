import React, { useState } from "react";
import { Container, Form, InputGroup, Button, Nav } from "react-bootstrap";
import { contact } from "../../network/apiCall";
import './contact.sass'

const Contact = () => {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [teamName, setTeamName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const onChangeFirstName = e => {
        setFirstName(e.target.value);
    };
    const onChangeLastName = e => {
        setLastName(e.target.value);
    };
    const onChangeTeamName = e => {
        setTeamName(e.target.value);
    };
    const onChangeEmail = e => {
        setEmail(e.target.value);
    };
    const onChangeMessage = e => {
        setMessage(e.target.value);
    };

    const onSubmitHandler = async () => {
        const {data} = await contact(teamName, firstName + " " + lastName, email, message);
        if (data.success) {
            setSuccessMessage("Mesajul tau s-a trimis cu succes!")
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } else {
            setErrorMessage("A intervenit o eroare in trimiterea mesajului tau! Te rugam sa incerci mai tarziu.")
        }
    };

    return(
        <Container className="contact-background">
        <Container className="contact text-center">
            <p className="large-text">Send us a message</p>
            <Form className="pt-5 my-form">
                <div className="wrapper">
                    <InputGroup className="mb-2">
                        <Form.Control placeholder="First Name" onChange={e => onChangeFirstName(e)} value={firstName}/>
                    </InputGroup>
                    <InputGroup className="mb-2">
                        <Form.Control placeholder="Last Name" onChange={e => onChangeLastName(e)} value={lastName}/>
                    </InputGroup>
                </div>

                <div className="wrapper">
                    <InputGroup className="mb-2">
                        <Form.Control placeholder="Team Name" onChange={e => onChangeTeamName(e)} value={teamName}/>
                    </InputGroup>
                    <InputGroup className="mb-2">
                        <Form.Control type="email" placeholder="Email" onChange={e => onChangeEmail(e)} value={email}/>
                    </InputGroup>
                </div>

                <InputGroup className="mb-2">
                    <Form.Control className="message" as="textarea" rows={5} placeholder="Message" onChange={e => onChangeMessage(e)} value={message}/>
                </InputGroup>

                <p className="text green">{successMessage}</p>
                <p className="text red">{errorMessage}</p>

                <div className="text-center">
                    <Button variant="primary" onClick={onSubmitHandler}>Send</Button>
                </div>
            </Form>
        </Container>
        </Container>
    );
}

export default Contact
