import React, { useState } from "react";
import { Container, Modal } from "react-bootstrap";
import './team.sass'
const TeamCard = (props) => {
    return (
        <Container className="teamOrg py-4 justify-content-center flex-column resize">
            <div
                className="circle-photo text-center borderless"
                onMouseEnter={(e) => e.currentTarget.querySelector('.photo').src = props.member.hoverPhoto}
                onMouseLeave={(e) => e.currentTarget.querySelector('.photo').src = props.member.photo}
            >
                <img className="photo" src={props.member.photo} alt={props.member.name} />
            </div>
            <div className="text-center"> 
                <div className="text-container">
                    {props.member.name.split(' ').map((namePart, index) => (
                        <h3 key={index}>{namePart}</h3>
                    ))}
                    <p>{props.member.position}</p>
                    <p>{props.member.phoneNumber}</p>
                </div>
                {/* Add the content you want to display under the circular photo */}
            </div>
        </Container>
    )
}

export default TeamCard