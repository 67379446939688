import React, { useContext, useState } from "react";
import {Navbar, Container, Nav, Button, Image} from 'react-bootstrap';
import AppContext from "../context";
import logo from "../assets/navbar-logo.png";
import { useNavigate } from "react-router-dom";

import './navbar.sass'

const NavBar = () => {

    const {isLoggedIn, setIsLoggedIn} = useContext(AppContext);
    const [expanded, setExpanded] = useState(false)

    const setToggle = () => {
        setExpanded(true)
    }
    const navigate = useNavigate();

    return(
        <Navbar className="treasure" expand="lg" sticky="top" onToggle={setToggle}>
            <Navbar.Toggle className="toggle" />
            <Navbar.Brand className="align-top"> <img className="logo" src={logo} alt={logo}/> </Navbar.Brand>
            <Navbar.Collapse>
                <Nav.Link className="me-auto" href="/"><Button variant="link" size="lg">Home</Button></Nav.Link>
                <Nav.Link className="me-auto" href="/#faq"><Button variant="link" size="lg">FAQ</Button></Nav.Link>
                <Nav.Link className="me-auto" href="/#sponsor"><Button variant="link" size="lg">Sponsori</Button></Nav.Link>
                {/* <Nav.Link className="me-auto" href="/#team"><Button variant="link" size="lg">Meet out team</Button></Nav.Link> */}
                <Nav.Link className="me-auto" href="/#contact"><Button variant="link" size="lg">Contact</Button></Nav.Link>
                <Nav.Link className="me-auto" href="/rules"><Button variant="link" size="lg">Regulament</Button></Nav.Link>
                <Nav.Link className="me-auto" href="/privacy"><Button variant="link" size="lg">Privacy</Button></Nav.Link>
                <Nav.Link className="me-auto" href="/howto"><Button variant="link" size="lg">Ghid Aplicatie</Button></Nav.Link>
                {isLoggedIn && <Nav.Link className="me-auto" href="/profile"><Button variant="link" size="lg">Profile</Button></Nav.Link>}
                {!isLoggedIn && <Nav.Link className="me-auto" href="/login"><Button variant="link" size="lg">Login</Button></Nav.Link>}
                {!isLoggedIn && <Nav.Link className="me-auto" href="/register"><Button variant="link" size="lg">Register</Button></Nav.Link>}
            </Navbar.Collapse>
        </Navbar>
    );
}

export default NavBar
