import React, { useEffect, useState} from "react";
import {Row, Image, Col, InputGroup, Container, Button, Form, Nav} from "react-bootstrap";
import { forgotPassword, resetPassword } from "../../network/apiCall";

import { GrMail } from "react-icons/gr"
import { ImArrowRight } from "react-icons/im"

import loginIcon from '../../assets/login.png'
import './forgot-pass.sass'
import { useParams } from "react-router-dom";

const ResetPass = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const token = useParams().token;

    const [message, setMessage] = useState("");    
    const [error, setError] = useState("");

    const onChangeEmail = e => {
        setEmail(e.target.value);
    };

    const onChangePassword = e => {
        setPassword(e.target.value);
    };

    const onChangeConfirmPassword = e => {
        setConfirmPassword(e.target.value);
    };


    const onSubmitHandler = async () => {
        const { data } = await resetPassword(email, password, confirmPassword, token);
        if (data.success) {
            setMessage(data.message);
            setError("");
            setEmail("");
            setPassword("");
            setConfirmPassword("");
        } else {
            setError(data.message);
            setMessage("");
            setEmail("");
            setPassword("");
            setConfirmPassword("");
        }
    };

    return(
        <Container className="login">
            <Row>
                <Col md={6} className="text-center image-container p-md-5 p-5 order-md-2">
                    <Image className="w-75 w-md-50" src={loginIcon} alt={loginIcon}></Image>
                </Col>
                <Col md={6} sm={12} xs={12} className="left-side my-auto order-md-1">
                    <p className="large-text">Reseteaza parola</p>
                    <p className="text">Te rugam sa iti introdci noua parola si incearca sa nu o uiti.</p>

                    <Form className="pt-5 my-form">
                        <InputGroup className="mb-2">
                            <Form.Control type="email" placeholder="Email" onChange={e => onChangeEmail(e)} value={email}/>
                            <GrMail className="form-icon" />
                        </InputGroup>
                        <InputGroup className="mb-2">
                            <Form.Control type="password" placeholder="New Password" onChange={e => onChangePassword(e)} value={password}/>
                            <GrMail className="form-icon" />
                        </InputGroup>
                        <InputGroup className="mb-2">
                            <Form.Control type="password" placeholder="Confirm Password" onChange={e => onChangeConfirmPassword(e)} value={confirmPassword}/>
                            <GrMail className="form-icon" />
                        </InputGroup>
                        <div className="text-center">
                            {error ? <p className="error">{error} </p> :
                                <p className="message">{message}</p>}
                            <Button variant="primary" onClick={onSubmitHandler}>
                                Reset Password
                                <ImArrowRight className="trailing-icon"/>
                            </Button>
                        </div>
                        <div className="text-center mt-2">
                            <Nav.Link href="/login">Revino la pagina de login</Nav.Link>
                            {/* <br/>
                            <p>Don't have an account? <Nav.Link href="/register">Create one today</Nav.Link></p> */}
                        </div>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default ResetPass;