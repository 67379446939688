import React from "react";
import { Container } from "react-bootstrap";
import Collapsible from 'react-collapsible';
import './faq.sass'
import { BsPlusLg } from 'react-icons/bs'

import { questions } from '../../assets/faq'

const Faq = () => {

    return(
        <Container className="faq">
            <Container className="wrapper-faq">
                <p className="large-text text-center">FAQ</p>
                <ul className="list-group list-group-flush">
                    {
                    questions.map((item, index) => 
                        <li className="list-group-item" key={index}>
                            <Collapsible trigger={
                                <>
                                    <p className="text">
                                        {item.question}
                                        <BsPlusLg className="trailing-icon" />
                                    </p>
                                </>
                            }>
                                <p dangerouslySetInnerHTML={{ __html: item.answer }}></p>
                            </Collapsible>
                        </li>    
                    )
                    }
                </ul>
            </Container>
        </Container>
    );
}

export default Faq
