import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import './footer.sass'
import telephone from '../../assets/phone.svg'
import { MdAttachEmail } from 'react-icons/md'
import map from '../../assets/footer-map.png'

import facebook from '../../assets/social-media-white/facebook.svg'
import youtube from '../../assets/social-media-white/youtube.svg'
import linkedin from '../../assets/social-media-white/linkedin.svg'
import instagram from '../../assets/social-media-white/instagram.svg'
import tiktok from '../../assets/social-media-white/tiktok.svg'
import lsac_site from '../../assets/social-media-white/lsac_site.svg'

const Footer = () => {

    return(
        <Container className="footer">
            <Row>
                <Col lg={6} className="left-side">
                    <div>
                        <p className="large-text pb-5">Find us at:</p>

                        <span className="wrapper text mb-5">
                            <img className="icon" src={telephone} alt={telephone} />
                            <p>&nbsp;&nbsp;&nbsp;+40 770 796 598</p>
                        </span>

                        <br />

                        <span className="wrapper text mb-5">
                            <MdAttachEmail className="icon" />
                            <p>&nbsp;&nbsp;&nbsp;treasure@lsacbucuresti.ro</p>
                        </span>

                        <div className="social-media">
                            <a href ="https://www.facebook.com/LsacBucuresti/">
                                <Image src={facebook} alt={facebook} />
                            </a>
                            <a href = "https://www.youtube.com/user/LSACBucuresti">
                                <Image src={youtube} alt={youtube} />
                            </a>
                            <a href = "https://ro.linkedin.com/company/lsac-bucuresti">
                                <Image src={linkedin} alt={linkedin} />
                            </a>
                            <a href = "https://www.instagram.com/lsacbucuresti/">
                                <Image src={instagram} alt={instagram} />
                            </a>
                            <a href = "https://www.tiktok.com/@lsacbucuresti">
                                <Image src={tiktok} alt={tiktok} />
                            </a>
                            <a href = "https://lsacbucuresti.ro/">
                                <Image src={lsac_site} alt={lsac_site} />
                            </a>
                        </div>
                    </div>
                </Col>
                <Col className="text-center image" lg={6}>
                    <Image className="w-100" src={map} alt={map} />
                </Col>
            </Row>
        </Container>
    );
}

export default Footer
