import React, {useEffect} from "react";
import Home from "./one-pager-components/home";
import Faq from "./one-pager-components/faq";
import Sponsors from "./one-pager-components/sponsors/sponsors";
import Team from "./one-pager-components/meet-our-team/team";
import './one-pager.sass';
import Contact from "./one-pager-components/contact";
import Footer from "./one-pager-components/footer";
import $ from 'jquery'
import TeamIssues from "./auth/team-issues";

const OnePager = () => {

    useEffect(() => {
        if (window.location.hash === "") return;
        setTimeout(() => $('html,body').animate({
            scrollTop: $(window.location.hash).offset().top
        }), 1000);
    }, [])

    return(
        <>

        <TeamIssues />

        <Home />

        <hr id="faq" className="solid"/>
        <Faq />

        <hr id="sponsor" className="solid"/>
        <Sponsors />

        <hr id="team" className="solid"/>
        <Team />

        <hr id="contact" className="solid"/>
        <Contact />
        <Footer />
        </>
    );
}

export default OnePager
