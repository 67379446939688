export const questions = [
    {
        question: "Cum mă înscriu?",
        answer: `Echipele vor fi formate din 3 studenți (indiferent de anul de studiu), <strong>dintre care minim unul va trebui sa fie student al Universității Politehnica București.</strong> Te poți înscrie fie cu echipa deja formată, fie cu încă un prieten/coleg, fie singur. Dacă te vei înscrie cu întreaga echipă, fiecare membru trebuie să își creeze un cont, astfel: căpitanul este cel care creează echipa, iar ceilalți membrii se pot alătura acesteia folosind token-ul aferent. Înscrierea se va face prin intermediul site-ului oficial Treasure Hunt Bucharest.`
    },
    {
        question: "Ce presupune rolul de Căpitan?",
        answer: `Căpitanul este cel care creează echipa, pe care ulterior o va reprezenta. El are rolul de a comunica celorlalți membrii informații cu privire la eveniment și de a asigura prezența acestora la concurs.`
    },
    {
        question: "Ce se va întâmpla la check-in?",
        answer: `În ziua evenimentului se va verifica componența echipelor pe baza carnetelor/legitimațiilor de student, pe care participanții trebuie să le prezinte fizic. În cazul în care formatul echipei nu este respectat sau actul nu este prezentat, <strong>echipa va fi descalificată.</strong> După validarea echipei, se va face un tutorial de utilizare a aplicației Treasure Hunt Bucharest (cu ajutorul căreia se va desfășura evenimentul). Va urma o sesiune de întrebări și răspunsuri. DISCLAIMER: În cazul în care participanților nu li s-a emis carnetul/legitimația de student, aceștia pot prezenta orice fel de dovadă care să ateste statutul de student. Exemplu: Contul studenti.pub.ro pentru studenții UNSTPB.`
    },
    {
        question: "Cum sunt evidențiate checkpoint-urile?",
        answer: `Toate checkpointurile competiției vor fi amplasate în zone accesibile și vor fi evidențiate atât în cadrul aplicației, prin intermediul unor pinuri specifice, cât și fizic, prin steaguri LSAC și voluntari ce vor purta tricoul LSAC.`
    },
    {
        question: "Unde pot vedea clasamentul?",
        answer: `Clasamentul nu va putea fi accesat în timp real. Acesta va putea fi vizualizat strict după terminarea competiției.`
    },
    {
        question: "Ce se întâmplă la checkpoint-uri?",
        answer: `După rezolvarea ghicitorii și aflarea locației în cadrul aplicației, jucătorii vor merge la checkpointul indicat, unde un voluntar LSAC le va scana codul QR aferent echipei, pentru a primi punctele. Aceștia vor avea posibilitatea de a îndeplini un challenge la checkpointul respectiv pentru puncte bonus, prin scanarea unui cod QR aferent provocării.`
    },
    {
        question: "Cum se folosește aplicația?",
        answer: `Aplicația este ușor de folosit, însă pentru orice nelămurire referitoare la funcționalitățile acesteia, vom publica un ghid care să vă ajute să vă familiarizați cu aceasta. De asemenea, în ziua concursului, la check-in va avea loc un tutorial de utilizare a aplicației, în cadrul discursului de început.`
    },
    {
        question: "Ce se întâmplă dacă aplic cu o echipă incompletă?",
        answer: `Persoanele care s-au înscris într-o formulă incompletă vor fi distribuite ulterior într-o altă echipă de către membrii din organizare. Aceștia vor fi sunați pentru a li se cere permisiunea partajării datelor de contact cu coechipierii lor. DISCLAIMER: Echipelor incomplete li se vor distribui membrii lipsă în ordinea înscrierilor. Exemplu: Dacă nu sunt echipe cu membrii lipsă, există șansa ca ultima persoană înscrisă singură să nu mai poată participa. De asemenea, studenții care nu sunt din UNSTPB și s-au înscris cu o echipă incompletă, pot risca să nu participe dacă, după stop înscrieri nu există studenți din UNSTPB cu care să li se formeze echipă.`
    },
    {
        question: "Ce fac dacă am o nelămurire în timpul evenimentului?",
        answer: `Va fi creat un grup de Whatsapp dedicat posibilelor probleme întâmpinate în timpul evenimentului.`
    },
    {
        question: "Ce se întâmplă dacă am terminat toate locațiile și mi-a rămas timp?",
        answer: `Cum unele sfârșituri aduc noi începuturi, fiți cu ochii în patru!`
    },
    {
        question: "Cum câștigăm?",
        answer: `Câștigătorii vor fi desemnați după terminarea timpului și calcularea finală a punctajelor, cu tot cu cele obținute de pe urma probelor/challenge-urilor. <strong>Punctajul va desemna câștigătorul, NU timpul!<strong>`
    },
    {
        question: `Ce se întâmplă după ce ne înregistrăm pe site?`,
        answer:`Veți fi contactați de către echipa de organizare pentru confirmare înainte de eveniment.`
    },
    {
        question: "Care sunt criteriile de departajare în situația în care 2 echipe termină cu același punctaj?",
        answer: `Dacă cele două echipe termină înainte de încheierea competiției, se va lua în considerare timpul de finalizare (momentul realizării ultimei acțiuni care a adus puncte). În caz contrar, se vor lua în considerare următoarele criterii, în ordine: primează un număr mai mare de checkpointuri parcurse, respectiv, un număr mai mic de hinturi folosite.`
    },
    {
        question: "Cum vor funcționa hint-urile?",
        answer: `Acestea vor costa puncte, respectiv, dacă veți apela la un hint pentru o ghicitoare, aceasta va fi punctată mai slab după finalizarea ei. Costul acestora este egal cu un procentaj din punctajul ghicitorii. Primul hint - 20%, al doilea - 30%, al treilea - 40%. Nu este obligatoriu ca orice ghicitoare să aibă 3 hint-uri.`
    },
    {
        question: `În ce constă side quest-urile/power up-urile de pe aplicație?`,
        answer: `Pe parcursul competiției, echipele vor primi câteva side quest-uri prin intermediul aplicației, pe care au opțiunea să le rezolve. Acestea nu sunt obligatorii, dar o astfel de provocare va duce la primirea unui power-up, tot în aplicație, ce va ajuta echipa. Participanții vor încărca în aplicație, în secțiunea potrivită dovada completării Side Quest-ului, urmând ulterior validării să se aloce Power Up-ul corespunzător.`
    },
    {
        question: "Ce se întâmplă după ce se termină cele 7 ore?",
        answer: `În momentul în care au trecut cele 7 ore, în cadrul aplicației locațiile și probele vor deveni indisponibile. La scurt timp după aceea, va avea loc festivitatea de premiere la Facultatea de Automatică și Calculatoare.`
    },
    {
        question: "Ce mijloace de transport am voie să folosesc?",
        answer: `Este permisă folosirea mijloacelor de transport în comun (metrou, tramvai, autobuz), biciclete și, în mod special, trotinete, fiind încurajată explorarea și familiarizarea cu orașul. Este <strong>INTERZISĂ</strong> utilizarea mașinilor proprii, a serviciilor de ridesharing sau a celor de taximetrie.`
    },
    {
        question: `Cum pot achiziționa bilete pentru mijloacele de transport în comun?`,
        answer: `La metrou se pot achiziționa călătorii (1 călătorie - 3 lei, 2 călătorii - 6 lei sau 10 călătorii - 25 lei) de la ghișeul aferent stației de metrou sau de la automatele de vândut cartele. De asemenea, călătoria se poate plăti direct cu cardul la aparatul de scanat cartele sau cu abonamentul. Pentru mijloacele de transport STB se pot cumpăra bilete de la ghișeele de lângă stații (prețul cartelelor fiind același ca la Metrorex). Dacă aveți abonament, îl puteți folosi pe acesta. În STB, călătoria poate fi plătită direct cu cardul la aparatele din interiorul mijlocului de transport, printr-un sms la numărul <strong>7458</strong> sau prin intermediul aplicației <strong>24Pay.</strong> Mai multe detalii găsiți direct de pe site-urile oficiale: <a href="https://www.stbsa.ro">https://www.stbsa.ro</a> și <a href="http://www.metrorex.ro">http://www.metrorex.ro.</a>`
    },
];
